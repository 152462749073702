/*!
 * Start Bootstrap - SB Admin Pro v1.3.0 (https://shop.startbootstrap.com/product/sb-admin-pro)
 * Copyright 2013-2020 Start Bootstrap
 * Licensed under SEE_LICENSE (https://github.com/StartBootstrap/sb-admin-pro/blob/master/LICENSE)
 */

(function ($) {
  "use strict";

  // Enable Bootstrap tooltips via data-attributes globally
  // $('[data-toggle="tooltip"]').tooltip();

  // Enable Bootstrap popovers via data-attributes globally
  // $('[data-toggle="popover"]').popover();

  // $(".popover-dismiss").popover({
  //   trigger: "focus",
  // });

  // Add active state to sidbar nav links
  var path = window.location.href; // because the 'href' property of the DOM element is the absolute path
  $("#layoutSidenav_nav .sidenav a.nav-link").each(function () {
    if (this.href === path) {
      $(this).addClass("active");
    }
  });

  // Toggle the side navigation
  $("#sidebarToggle").on("click", function (e) {
    console.log("sidebar click");
    e.preventDefault();
    $(".body").toggleClass("sidenav-toggled");
  });

  // Activate Feather icons
  //feather.replace();

  // Activate Bootstrap scrollspy for the sticky nav component
  // $("body").scrollspy({
  //   target: "#stickyNav",
  //   offset: 82,
  // });

  // Scrolls to an offset anchor when a sticky nav link is clicked
  $('.sidenav a').on("click", function (e) {
    let a = document.querySelectorAll('.sidenav a.active');
    console.log('a',a)

    a.forEach(function(z) {
      z.classList.remove('active');
    });

    let b = document.querySelectorAll('.sidenav a');

    $(this).addClass('active');


  });

  // Click to collapse responsive sidebar
  $("#layoutSidenav_content").click(function () {
    const BOOTSTRAP_LG_WIDTH = 992;
    if (window.innerWidth >= 992) {
      return;
    }
    if ($(".body").hasClass("sidenav-toggled")) {
      $(".body").toggleClass("sidenav-toggled");
    }
  });

  // Init sidebar
  let activatedPath = window.location.pathname.match(/([\w-]+\.html)/, "$1");

  if (activatedPath) {
    activatedPath = activatedPath[0];
  } else {
    activatedPath = "index.html";
  }

  let targetAnchor = $('[href="' + activatedPath + '"]');
  let collapseAncestors = targetAnchor.parents(".collapse");

  targetAnchor.addClass("active");

  collapseAncestors.each(function () {
    $(this).addClass("show");
    $('[data-target="#' + this.id + '"]').removeClass("collapsed");
  });

  $("#accordionSidenav a.nav-link").on("click", function (e) {
    console.log("ok navlink");
    const BOOTSTRAP_LG_WIDTH = 992;
    if (window.innerWidth >= 992) {
      return;
    }
    if ($(".body").hasClass("sidenav-toggled")) {
      $(".body").toggleClass("sidenav-toggled");
    }
  });

  /*$("#accordionSidenavPages a.nav-link.active").on("click", function (e) {
    console.log("ok navlink");
    const BOOTSTRAP_LG_WIDTH = 992;
    if (window.innerWidth >= 992) {
      return;
    }
    if ($(".body").hasClass("sidenav-toggled")) {
      $(".body").toggleClass("sidenav-toggled");
    }
  });*/
  
  
  
  

  //FROM scripts-ui-kit

  // Collapse Navbar
  // Add styling fallback for when a transparent background .navbar-marketing is scrolled
  var navbarCollapse = function () {
    if ($(".navbar").length === 0) {
      return;
    }
    if ($(".navbar").offset().top > 12) {
      $(".navbar").removeClass("bg-transparent");
      $(".navbar").addClass("bg-b-blue");
    } else {
      $(".navbar").addClass("bg-transparent");
      $(".navbar").removeClass("bg-b-blue");
    }
  };
  // Collapse now if page is not at top
  navbarCollapse();
  // Collapse the navbar when page is scrolled
  $(window).scroll(navbarCollapse);
})(jQuery);
